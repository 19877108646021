
$(document).ready(function(){
  getVideoChannel()
})
function getVideoChannel(){
  actionWebApiRestrict('/v1/stream/historia-channel', {}, 'GET').done(function(json) {
    if (json.status == 600) {
      streamData = json.data;
      if(streamData.length > 0){
        var append = ''
        $.each(streamData, function(key, value){
          append += '<a href="'+value.url+'" title="'+value.category.name+'" class="home-channel-items" onclick="panelTrackingPushSection(\'Historia Channel\',\''+value.url+'\',\'Dibawah Agenda Historia\',\''+value.category.name+'\')">'
          append += '<span class="home-channel-list-image">'
          append += '<span class="home-channel__play"><img class="lazy-video" data-original="/web/assets/img/play_button.png" alt=">"></span>'
          append += '<img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" data-original="'+value.cover.medium+'" alt="logo channel" class="lazy-video channel-image-cover">'
          if(value.category.logo){
            append += '<span class="home-channel-list-logo"><img class="lazy-video" data-original="'+value.category.logo+'" alt="'+value.category.name+'"></span>'
          }
          append += '</span>'
          append += '</a>'
        })
        $('#home-channel').append(append)
        $('.variable-width-video').slick({
          speed: 300,
          slidesToShow: 1,
          infinite: false,
          variableWidth: true
        });
        $('.slick-prev').html("")
        $('.slick-next').html("")
        $('.slick-dots button').html("")
        $('.lazy-video').lazyload().trigger("appear")
      }else{
        $('.home-channel-header').parent().hide()
      }
    }
  })
}
var panelTrackingPushSection = function(item, target, position, title) {
  dataLayer.push({
    'event': 'paneltracking',
    'panelname': item,
    'panelposition': position,
    'panelpage_url': target,
    'panelpage_title': title
  });
}